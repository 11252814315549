import { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './styles'; // Importando os estilos SX

const ButtonDropdown = ({ buttonType = 'primary', title = '', items = [], ...props }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles; // Atribuindo os estilos SX importados a uma constante

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e, item) => {
    if (item && item.onClick) {
      item.onClick();
      setAnchorEl(null);
      return;
    }
    
    if(e.target.dataset.navigate && e.target.dataset.opennewtab === 'true') {
      window.open(e.target.dataset.navigate, '_blank');
    } else if (e.target.dataset.navigate) {
      navigate(e.target.dataset.navigate);
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        key={props.key}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={buttonType === 'primary' ? classes.btnDropdownPrimary : classes.btnDropdownOutlined} // Aplicando os estilos condicionalmente
      >
        {title}
        <ArrowDropDownIcon sx={{ marginLeft: 1 }} size={24} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {items.map((item) => (
          <MenuItem
            key={item.title.split(' ').join('-')}
            sx={classes.menuDropdown} // Aplicando os estilos para o MenuItem
            data-navigate={item.href || '/'}
            data-opennewtab={item.openNewTab || false}
            onClick={(e) => handleClose(e, item)}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ButtonDropdown;
