import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Breadcrumbs } from '@mui/material';
import ButtonDropdown from '../ButtonDropdown';
import i18n from '../../helpers/i18n';
import {
    Container,
    BreadcrumbsContainer,
    TitleTypography,
    BreadcrumbLink,
    ActualBreadcrumb,
    ButtonsContainer,
    HeaderItems,
} from './styles';

import BASE_PATH from '../../helpers/consts/';

const Header = ({ title = "", breadCrumbs = [], showButtons = false, deviceName = ""}) => {

    const {BASE_PATH_API} = BASE_PATH;

    const getEmployeesWithFaceId = async () => {
        try {
            const response = await fetch(`${BASE_PATH_API}funcionarios/getDataFromEmployeesWithFaceid`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            
            const result = await response.json();
            
            let employees = [];
            if (result && result.funcionarios && Array.isArray(result.funcionarios)) {
                employees = result.funcionarios;
            } else {
                console.warn(i18n.t('pages.errors.employees.noDataFound'), result);
                alert(i18n.t('pages.errors.employees.unexpectedFormat'));
                return;
            }
            
            const csvRows = employees.map(e => [
                e.matricula || '',
                e.nome || '',
                e.cadastrado || '',
                e.novo_registro_habilitado || '',
                e.ultimo_registro || ''
            ].join(","));
            
            const csvContent = "data:text/csv;charset=utf-8," + ["matricula,nome,cadastrado,novo_registro_habilitado,ultimo_registro", ...csvRows].join("\n");

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "employees_with_faceid.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error fetching data or generating CSV:", error);
            alert("Error fetching data: " + error.message);
        }
    };

    const devicesMenu = [
        { title: i18n.t('home.newDevice'), href: '/create' },
        { title: i18n.t('home.multipleDevice'), href: '/multiple' },
        { title: i18n.t('home.importDevice'), href: 'https://app.ahgora.com.br/importa?import=batidaonline', openNewTab: true },
    ];

    const downloadMenu = [
        { title: i18n.t('home.deviceAh01'), href: 'https://app.ahgora.com.br/files/Ahgora-AH01.msi', openNewTab: true },
        { title: i18n.t('home.manualAh01'), href: 'https://9366697.fs1.hubspotusercontent-na1.net/hubfs/9366697/%5BCLIENTES%5D%20Manuais/DHW%20M005%20-%20Manual%20de%20instala%C3%A7%C3%A3o%20do%20Ah01%20(1).pdf', openNewTab: true },
        { title: i18n.t('home.thirdPartyDevice'), href: 'https://app.ahgora.com.br/downloads/AhgoraPontoWeb-instalador.exe', openNewTab: true },
        { 
            title: i18n.t('home.faceId'), 
            onClick: getEmployeesWithFaceId,
            customHandler: true,
            action: 'function'
        }
    ];

    const handleBreadcrumbClick = (href) => {
        if (href) {
            if (href.startsWith('http')) {
                window.open(href);
            } else {
                window.top.location.href = href;
            }
        }
    };

    return (
        <Grid item xs={12}>
        <Container>
            <BreadcrumbsContainer>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <BreadcrumbLink  underline="hover" color="inherit" 
                            onClick={() => handleBreadcrumbClick(`${BASE_PATH_API}painel`)}>
                                {i18n.t('breadcrumb.pontoweb')}
                        </BreadcrumbLink>
                        <BreadcrumbLink  underline="hover" color="inherit" 
                            onClick={() => handleBreadcrumbClick(`${BASE_PATH_API}equipamentos`)}
                           >
                                {i18n.t('breadcrumb.equipment')}
                        </BreadcrumbLink>
                       
                        {breadCrumbs.map((item, index) => (
                            <React.Fragment key={item.title.split(' ').join('-')}>
                                {index < breadCrumbs.length - 1 ? (
                                    <Link 
                                        to={item.href}
                                        style={{ 
                                            fontWeight: 600,
                                            fontSize: 12,
                                            cursor: 'pointer',
                                            color: 'var(--ahg-waterloo-lighten1)',
                                            textDecoration: 'none'
                                        }}>
                                        {item.title}
                                    </Link>
                                ) : (
                                    <ActualBreadcrumb>
                                        {item.title}
                                    </ActualBreadcrumb>
                                )}
                            </React.Fragment>
                        ))}
                </Breadcrumbs>
            </BreadcrumbsContainer>
            <HeaderItems>
                <TitleTypography>{title} {deviceName ? deviceName : ''}</TitleTypography>
                {showButtons && (
                    <ButtonsContainer>
                        <ButtonDropdown key='downloadsBtn' buttonType='outlined' title={i18n.t('home.downloadButton')} items={downloadMenu}/>
                        <ButtonDropdown key='devicesBtn' title={i18n.t('home.createDeviceButton')} items={devicesMenu} />
                    </ButtonsContainer>
                )}
            </HeaderItems>
        </Container>
    </Grid>
);

}

export default Header;