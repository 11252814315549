import React from 'react';
import { Select, Switch, Checkbox } from '../Layout';
import { TextField, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import timezones from '../../helpers/consts/timezones';
import i18n from '../../helpers/i18n';
import { TitleH3, TitleSpan, gridItemStyle, checkboxStyle } from '../CreateDeviceContent/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import InfoTooltip from '../InfoTooltip';

export default function DeviceSection({ formik, cnpjs, deviceTypes, isLoadingLocationsAndCnpjs, variant, deviceArchived }) {

    return (
        <Grid style={gridItemStyle} item xs={12} md={6} formik={formik}>
            <TitleH3>
                {i18n.t('pages.editDevice.activateDevice')}
                <TitleSpan>{i18n.t('pages.newDevice.mandatory')}</TitleSpan>
            </TitleH3>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}>
                {variant === 'edit' && (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '6px 0 6px 16px',
                        backgroundColor: 'var(--ahg-waterloo-lighten5)',
                        borderRadius: '4px'
                    }}>
                        <Checkbox
                            label={'Ativar este dispositivo'}
                            checked={formik.values.active}
                            onChange={(event) => formik.setFieldValue('active', event.target.checked)}
                            disabled={deviceArchived}
                        />
                    </div>

                )}
                <Tooltip title={variant === 'edit' ? i18n.t('pages.editDevice.disabledChangeDevice') : ""} arrow>
                    <div>
                        <Select
                            items={deviceTypes}
                            id="deviceType"
                            title={i18n.t('pages.newDevice.deviceType')}
                            value={formik.values.deviceType}
                            onChange={(event) => formik.setFieldValue('deviceType', event.target.value)}
                            onBlur={formik.handleBlur}
                            error={formik.touched.deviceType && Boolean(formik.errors.deviceType)}
                            disabled={variant === 'edit' || deviceArchived}
                        />
                    </div>
                </Tooltip>
                <Select
                    items={cnpjs}
                    id="cnpj"
                    title={i18n.t('pages.newDevice.cnpj')}
                    value={formik.values.cnpj}
                    disabled={isLoadingLocationsAndCnpjs || deviceArchived}
                    onChange={(event) => formik.setFieldValue('cnpj', event.target.value)}
                    onBlur={formik.handleBlur}
                    error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}

                />
                <TextField
                    id="name"
                    label={i18n.t('pages.newDevice.deviceName')}
                    value={formik.values.name}
                    onChange={(event) => formik.setFieldValue('name', event.target.value)}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    disabled={deviceArchived}
                    sx={{
                        width: '100%',
                        '& .MuiInputBase-input': {
                            fontSize: '14px',
                            fontFamily: 'Open Sans',
                            color: 'var(--ahg-waterloo-darken1)'
                        },
                        '& .MuiInputLabel-root': {
                            fontSize: '14px',
                            fontFamily: 'Open Sans',
                            color: 'var(--ahg-waterloo-darken1)'
                        },
                        '& .MuiFormHelperText-root': {
                            fontSize: '12px',
                            fontFamily: 'Open Sans',
                            color: 'var(--ahg-waterloo-darken1)'
                        }
                    }}
                />
                <Select
                    items={timezones}
                    id="timezone"
                    title={i18n.t('pages.newDevice.timezone')}
                    value={formik.values.timezone}
                    onChange={(event) => formik.setFieldValue('timezone', event.target.value)}
                    onBlur={formik.handleBlur}
                    error={formik.touched.timezone && Boolean(formik.errors.timezone)}
                    enableSearch={true}
                    disabled={deviceArchived}
                />
                <div style={checkboxStyle}>
                    <Switch
                        label={i18n.t('pages.newDevice.expirationDate')}
                        checked={formik.values.expirationDate}
                        onChange={(event) => formik.setFieldValue('expirationDate', event.target.checked)}
                        disabled={deviceArchived}
                    />
                    <InfoTooltip tooltip={i18n.t('pages.newDevice.expirationDateDescription')} />
                </div>
                {formik.values.expirationDate && (
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '8px', justifyContent: 'start' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                disabled={deviceArchived}
                                format="DD/MM/YYYY"
                                sx={{
                                    marginTop: '16px !important',
                                    marginLeft: '16px !important',
                                    width: 'calc(100% - 32px)',
                                    fontSize: 12,
                                    '& .MuiInputBase-root': {
                                        flexDirection: 'row-reverse',
                                    },
                                    '& .MuiButtonBase-root': {
                                        color: '#2D9CDB',
                                    },
                                }}
                                slotProps={{ textField: { placeholder: i18n.t('filter.from') } }}
                                value={formik.values.startPeriod}
                                onChange={(val) => formik.setFieldValue('startPeriod', val)}

                            />

                            <DatePicker
                                disabled={deviceArchived}
                                format="DD/MM/YYYY"
                                sx={{
                                    marginTop: '16px !important',
                                    marginLeft: '16px !important',
                                    width: 'calc(100% - 32px)',
                                    fontSize: 12,
                                    '& .MuiInputBase-root': {
                                        flexDirection: 'row-reverse',
                                    },
                                    '& .MuiButtonBase-root': {
                                        color: '#2D9CDB',
                                    },
                                }}
                                slotProps={{ textField: { placeholder: i18n.t('filter.to') } }}
                                value={formik.values.endPeriod}
                                onChange={(val) => formik.setFieldValue('endPeriod', val)}
                            />
                        </LocalizationProvider>
                    </div>
                )}
            </div>
        </Grid>)
}